// // ** React Imports
// import { Navigate, useNavigate } from "react-router-dom";
// import { useContext, Suspense, useEffect } from "react";
// import jwt from "jsonwebtoken";
// import Cookies from "js-cookie";

// // ** Context Imports
// import { AbilityContext } from "@src/utility/context/Can";
// import getTokenService from "../../../services/getTokenService";

// const PrivateRoute = ({ children, route }) => {
//   // ** Hooks & Vars
//   const ability = useContext(AbilityContext);

//   const username = JSON.parse(localStorage.getItem("userData")).username;
//   // const username = Cookies.get("userData").username;

//   const navigate = useNavigate();

//   if (route) {
//     let action = null;
//     let resource = null;
//     let restrictedRoute = false;

//     if (route.meta) {
//       action = route.meta.action;
//       resource = route.meta.resource;
//       restrictedRoute = route.meta.restricted;
//     }

//   }

//   return <Suspense fallback={null}>{children}</Suspense>;
// };

// export default PrivateRoute;
// ** React Imports
import { Suspense, useContext } from "react";
import { Navigate } from "react-router-dom";

// ** Context Imports
import { AbilityContext } from "@src/utility/context/Can";

const PrivateRoute = ({ children, route }) => {
  // ** Hooks & Vars
  const ability = useContext(AbilityContext);
  const user = JSON.parse(localStorage.getItem("userData"));

  if (route) {
    let action = null;
    let resource = null;
    let restrictedRoute = false;

    if (route.meta) {
      action = route.meta.action;
      resource = route.meta.resource;
      restrictedRoute = route.meta.restricted;
    }
    // console.log(restrictedRoute);

    if (!user) {
      // console.log("HI");
      return <Navigate to="/login" />;
      // return <Navigate to="/leave-request" />;
    }
    if (user && restrictedRoute) {
      return <Navigate to="/" />;
    }
    if (user && restrictedRoute && user.role === "client") {
      return <Navigate to="/access-control" />;
    }
    if (user && !ability.can(action || "read", resource)) {
      return <Navigate to="/misc/not-authorized" replace />;
    }
  }

  return <Suspense fallback={null}>{children}</Suspense>;
};

export default PrivateRoute;
