// ** Navigation imports
import home from './home'
import leaveSystem from './leaveSystem'
import timeRecord from './timeRecord'

// ** Merge & Export
export default [
  ...home,
  // ...apps,
  ...leaveSystem,
  //...flexTimeSystem,
  ...timeRecord
  // ...dashboards,
  // ...pages,
  // ...uiElements,
  // ...forms,
  // ...tables,
  // ...charts,
  // ...others
]
