// ** Redux Imports
import { createSlice } from "@reduxjs/toolkit";
import Cookies from "js-cookie";

// ** UseJWT import to get config
import useJwt from "@src/auth/jwt/useJwt";
import LogoutServices from "../services/LogoutService";
import { getUserData } from "../utility/Utils";

const config = useJwt.jwtConfig;

const initialUser = () => {
  const item = getUserData();
  //** Parse stored json or if none return initialValue
  return item ? item : {};
};

export const authSlice = createSlice({
  name: "authentication",
  initialState: {
    isLoggedIn: false,
    userData: initialUser(),
  },
  reducers: {
    setLogin(state, action) {
      state.isLoggedIn = action.payload;
    },
    handleLogin: (state, action) => {
      // console.log(action);

      state.userData = action.payload;
      state[config.storageTokenKeyName] =
        action.payload[config.storageTokenKeyName];
      state[config.storageRefreshTokenKeyName] =
        action.payload[config.storageRefreshTokenKeyName];
      localStorage.setItem("userData", JSON.stringify(action.payload));
      localStorage.setItem(
        config.storageTokenKeyName,
        JSON.stringify(action.payload.accessToken)
      );
      localStorage.setItem(
        config.storageRefreshTokenKeyName,
        JSON.stringify(action.payload.refreshToken)
      );
    },
    handleLogout: (state) => {
      //const username = JSON.parse(localStorage.getItem("userData")).username;

      // LogoutServices.deleteToken({ username: username });

      state.userData = {};
      state[config.storageTokenKeyName] = null;
      state[config.storageRefreshTokenKeyName] = null;
      // ** Remove user, accessToken & refreshToken from localStorage
      localStorage.removeItem("userData");
      localStorage.removeItem(config.storageTokenKeyName);
      localStorage.removeItem(config.storageRefreshTokenKeyName);

      window.location.replace(process.env.REACT_APP_LEAVE_SYSTEM_LOGIN);
    },
  },
});

export const { setLogin, handleLogin, handleLogout } = authSlice.actions;

export const selectIsLoggedIn = (state) => state.auth.isLoggedIn;
export const selectUserData = (state) => state.auth.userData;

export default authSlice.reducer;
