// ** React Imports
import React from 'react'
import { useNavigate } from 'react-router-dom'

// import { Redirect } from "react-router-dom";

// ** Router Import

// ** Core Layout Import
// !Do not remove the Layout import
// import Layout from "@layouts/VerticalLayout";

// ** Menu Items Array

// ** Actions

// ** Context
import { AbilityContext } from '@src/utility/context/Can'

// ** Utils
import { isUserLoggedIn, selectThemeColors } from '@utils'

// ** React Imports
import { useContext, useEffect, useState } from 'react'

// ** Third Party Components
import classnames from 'classnames'

// ** Custom Hook
import { useDispatch } from 'react-redux'

import { Controller, useForm } from 'react-hook-form'

// ** Custom Hook
import AsyncSelect from 'react-select/async'
import { fetchUserGroup } from '../../../../components/react-select/fetchUserGroup'

// ** Reactstrap Imports
import { Col, FormFeedback, Row } from 'reactstrap'
import { DefaultRoute } from '../../../../router/routes'
import { getUserData } from '../../../../utility/Utils'

const UserGroupDropdown = ({ userGroup, setUserGroup, isFetchData, setIsFetchData }) => {
  // ** State
  const dispatch = useDispatch()
  // const navigate = useNavigate();
  const ability = useContext(AbilityContext)
  const [userData, setUserData] = useState(null)
  const [isLoadingGetUserGroup, SetisLoadingGetUserGroup] = useState(false)
  const [dropdownOpen, setDropdownOpen] = useState(false)

  // ** react-hook-form
  const {
    handleSubmit,
    register,
    formState: { errors },
    setValue: setValue,
    getValues,
    resetField,
    control: control
  } = useForm()

  //** ComponentDidMount
  useEffect(() => {
    if (isUserLoggedIn() !== null) {
      setUserData(getUserData())
    }
  }, [])

  let navigate = useNavigate()
  const routeChange = () => {
    let path = DefaultRoute
    navigate(path)
  }

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen)
  }

  // useEffect
  useEffect(() => {
    if (dropdownOpen == false) {
      setValue('userGroup', '')
      resetField('userGroup')
      setValue('userGroupName', '')
      resetField('userGroupName')
    }
  }, [dropdownOpen])

  const [items, setItems] = useState([])

  const renderUserGroupItems = () => {
    return (
      <Row form>
        <Col className="col-12">
          <Controller
            id="userGroup"
            control={control}
            name="userGroup"
            rules={{ required: 'User Group is required' }}
            render={({ field }) => (
              <>
                <AsyncSelect
                  isClearable
                  cacheOptions
                  defaultOptions
                  loadOptions={fetchUserGroup}
                  getOptionLabel={(e) => e.USER_GROUP_NAME}
                  getOptionValue={(e) => e.USER_GROUP_ID}
                  className={classnames('react-select', {
                    'is-invalid': errors.userGroup
                  })}
                  classNamePrefix="select"
                  theme={selectThemeColors}
                  {...field}
                  placeholder="Select User Group ..."
                />
                {errors.userGroup && <FormFeedback type="invalid">{errors.userGroup.message}</FormFeedback>}
              </>
            )}
          />
        </Col>
      </Row>
    )
  }

  const onSubmit = (props) => {
    routeChange()
    setUserGroup(getValues('userGroup').USER_GROUP_NAME)

    const datItems = JSON.parse(localStorage.getItem('userData'))
    // console.log("dataItems : ", datItems);
    // const datItems = JSON.parse(Cookies.get("userData"));
    if (datItems) {
      datItems.userGroup = getValues('userGroup').USER_GROUP_NAME
      datItems.userGroupId = getValues('userGroup').USER_GROUP_ID
      localStorage.setItem('userData', JSON.stringify(datItems))
      // Cookies.set("userData", JSON.stringify(datItems), { expires: 1 });
    }

    setUserData(getUserData())
    setIsFetchData(true)
    setDropdownOpen(false)
  }

  /*eslint-enable */

  return (
    <>
      <span className="fw-bold m-1">{userData && userData['username']}</span>

      {/* <UncontrolledDropdown
        // isOpen={dropdownOpen}
        toggle={toggleDropdown}
        tag="li"
        className="dropdown-notification nav-item me-0"
        style={{ zIndex: 999 }}
      >
        <DropdownToggle href="/" tag="a" className="nav-link dropdown-user-link" onClick={(e) => e.preventDefault()}>
          <div>
            <span className="user-name fw-bold ">{userData && userData['username']}</span>
            <span className="user-status custom-fs">{userData && userData.userGroup}</span>
          </div>
        </DropdownToggle>

        <DropdownMenu end tag="ul" className="dropdown-menu-media" style={{ overflow: 'visible' }}>
          <li className="dropdown-menu-header">
            <DropdownItem className="d-flex" tag="div" header>
              <h6 className="user-group-title mb-0">User Group</h6>
            </DropdownItem>
          </li>
          <CardBody>{renderUserGroupItems()}</CardBody>

          <li className="dropdown-menu-footer">
            <Button color="primary" outline={isLoadingGetUserGroup} onClick={handleSubmit(onSubmit)} block>
              {isLoadingGetUserGroup ? (
                <>
                  <Spinner size="sm" />
                  <span className="ms-50">Loading...</span>
                </>
              ) : (
                <>Change User Group</>
              )}
            </Button>
          </li>
        </DropdownMenu>
      </UncontrolledDropdown> */}
    </>
  )
}

export default UserGroupDropdown
