import { useQuery, useMutation } from "react-query";
import SecurityCenterServices from "../../../services/SecurityCenterServices";

// - getMenu
const getMergeMenu = (dataItem) => {
  return SecurityCenterServices.getMenuMergeUserGroupByUserNameAndApplicationId(
    dataItem
  );
};

const useGetMergeMenu = (onSuccess, onError, dataItem, isFetchData) => {
  return useQuery("LeaveSystemMergeMenu", () => getMergeMenu(dataItem), {
    onSuccess,
    onError,
    cacheTime: 0,
    enabled: isFetchData,
  });
};

export { useGetMergeMenu };
