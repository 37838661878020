// ** React Imports
import { useEffect } from 'react'
import { NavLink } from 'reactstrap'

// ** Icons Imports
import { Circle, Disc } from 'react-feather'

// ** Config
import { useSkin } from '@hooks/useSkin'

// ** Utils
import { getUserData } from '@utils'
import { DefaultRoute } from '../../../../../router/routes'

const image1 = require('@src/assets/images/logo/leave-system-logo.png').default
const image2 = require('@src/assets/images/logo/leave-system-logo-dark.png').default

const VerticalMenuHeader = (props) => {
  // ** Props
  const { menuCollapsed, setMenuCollapsed, setMenuVisibility, setGroupOpen, menuHover } = props

  // ** Vars
  const user = getUserData()
  const { skin } = useSkin()

  // ** Reset open group
  useEffect(() => {
    if (!menuHover && menuCollapsed) setGroupOpen([])
  }, [menuHover, menuCollapsed])

  // ** Menu toggler component
  const Toggler = () => {
    if (!menuCollapsed) {
      return (
        <Disc
          size={20}
          data-tour="toggle-icon"
          className="text-primary toggle-icon d-none d-xl-block"
          onClick={() => setMenuCollapsed(true)}
        />
      )
    } else {
      return (
        <Circle
          size={20}
          data-tour="toggle-icon"
          className="text-primary toggle-icon d-none d-xl-block"
          onClick={() => setMenuCollapsed(false)}
        />
      )
    }
  }

  return (
    <>
      <div className="navbar-header">
        <ul className="nav navbar-nav flex-row">
          <li className="nav-item me-auto">
            <NavLink
              href={DefaultRoute}
              // to={user ? getHomeRouteForLoggedInUser(user.role) : "/"}
              className="navbar-brand"
            >
              <span className="brand-logo">
                <img src={skin === 'dark' ? image2 : image1} alt="logo" />
              </span>
              {/* <h2 className="brand-text mb-0">{themeConfig.app.appName}</h2> */}
            </NavLink>
          </li>

          {/* <li className="nav-item nav-toggle">
          <div className="nav-link modern-nav-toggle cursor-pointer">
          <Toggler />
          <X
          onClick={() => setMenuVisibility(false)}
          className="toggle-icon icon-x d-block d-xl-none"
          size={20}
          />
          </div>
        </li> */}
        </ul>
      </div>
    </>
  )
}

export default VerticalMenuHeader
