import api_request from "../utility/api-request-permission";
import api_request_user from "../utility/api-request";
import LoginAPI from "../api/LoginAPI";

export default class SecurityCenterServices {
  static create(SecurityCenterProperty) {
    return api_request({
      url: `${LoginAPI.API_ROOT_URL}/create`,
      method: "POST",
      data: SecurityCenterProperty,
    });
  }

  static search(params) {
    return api_request({
      url: `${ProductTypeAPI.API_ROOT_URL}/search`,
      params: { data: params },
      method: "GET",
    });
  }

  static get(SecurityCenterProperty) {
    return api_request({
      url: `${LoginAPI.API_ROOT_URL}/get`,
      params: { data: JSON.stringify(SecurityCenterProperty) },
      method: "GET",
    });
  }

  static update(SecurityCenterProperty) {
    return api_request({
      url: `${LoginAPI.API_ROOT_URL}/update`,
      method: "PATCH",
      data: SecurityCenterProperty,
    });
  }

  static delete(SecurityCenterProperty) {
    return api_request({
      url: `${LoginAPI.API_ROOT_URL}/delete`,
      data: SecurityCenterProperty,
      method: "DELETE",
    });
  }

  static getUserGroup(params) {
    return api_request({
      url: `${LoginAPI.API_ROOT_URL}/GetUserGroup`,
      params: { data: params },
      method: "GET",
    });
  }

  static getUserGroupByUsernameAndLikeUserGroupName(SecurityCenterProperty) {
    return api_request({
      //url: `${LoginAPI.API_ROOT_URL}/get-group`,
      url: `${LoginAPI.API_ROOT_URL}/getUserGroupByUsernameAndLikeUserGroupName`,
      // data: SecurityCenterProperty,
      // method: "POST",
      params: { data: SecurityCenterProperty },
      method: "GET",
    });
  }

  static getMenu(SecurityCenterProperty) {
    return api_request({
      url: `${LoginAPI.API_ROOT_URL}/GetMenu`,
      // data: SecurityCenterProperty,
      // method: "POST",
      params: { data: SecurityCenterProperty },
      method: "GET",
    });
  }

  static getUserByToken(params) {
    return api_request_user({
      url: `api/v1/auth/checkUser`,
      params: { data: params },
      method: "GET",
    });
  }

  // Merge By Poodit
  static getMenuMergeUserGroupByUserNameAndApplicationId(params) {
    return api_request({
      url: `${LoginAPI.API_ROOT_URL}/getMenuMergeUserGroupByUserNameAndApplicationId`,
      params: { data: params },
      method: "GET",
    });
  }
}
