// ** Dropdowns Imports
import IntlDropdown from './IntlDropdown'
import UserDropdown from './UserDropdown'

// ** Third Party Components
import { Monitor, Moon, Sun } from 'react-feather'

// ** Reactstrap Imports
import UserGroupDropdown from './UserGroupDropdown'

import { Avatar } from '@nextui-org/react'

// ** Custom Components
import { useMediaQuery } from 'react-responsive'
import CustomizerNavbar from '../../../../components/common/CustomizerNavbar'

const NavbarUser = (props) => {
  // ** Props
  const { skin, setSkin, userGroup, setUserGroup, isFetchData, setIsFetchData } = props
  const isTabletOrMobile = useMediaQuery({ query: process.env.REACT_APP_RESPONSIVE_TABLET_OR_MOBILE })
  const ThemeToggler = () => {
    if (skin === 'dark') {
      return (
        <Avatar
          color="light"
          onClick={() => setSkin('light')}
          tyle={{ marginRight: 12 }}
          squared
          icon={<Sun size={20} fill="currentColor" />}
          style={{ cursor: 'pointer' }}
        />
      )
    } else {
      return (
        <Avatar
          css={{ color: '$currentColor' }}
          onClick={() => setSkin('dark')}
          tyle={{ marginRight: 12 }}
          squared
          icon={<Moon size={20} fill="currentColor" />}
          style={{ cursor: 'pointer' }}
        />
      )
    }
  }

  return (
    <ul className="nav navbar-nav align-items-center ms-auto">
      <Monitor
        style={{ marginRight: 9, cursor: 'pointer' }}
        className="ficon"
        onClick={() => window.location.replace(process.env.REACT_APP_PORTAL_PAGE_URL)}
      />
      {isTabletOrMobile ? null : <CustomizerNavbar />}
      <IntlDropdown /> <ThemeToggler />
      <UserGroupDropdown
        userGroup={userGroup}
        setUserGroup={setUserGroup}
        isFetchData={isFetchData}
        setIsFetchData={setIsFetchData}
      />
      <UserDropdown />
    </ul>
  )
}
export default NavbarUser
