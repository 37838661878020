// ** React Imports
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import React from "react";

// ** Core Layout Import
// !Do not remove the Layout import
import Layout from "@layouts/VerticalLayout";

// ** Menu Items Array
import navigation from "@src/navigation/vertical";

// ** Utils
import { useState } from "react";

import { getUserGroupName, getUserName } from "../auth/LoginUser";
import { useGetMenu } from "../utility/hooks/react-query/useSecurityCenterData";
import { useGetMergeMenu } from "../utility/hooks/react-query/useGetMergeMenu";
import {
  ToastMessageError,
  ToastMessageSuccess,
  ToastMessageWarning,
} from "../components/common/ToastMessage";
import { useEffect } from "react";
import { useSearchEmployeeByEmployeeCode } from "../utility/hooks/react-query/useEmployeeData";

// ** Function

const getUrlParamSearch = (userGroup) => {
  // let params = ``;
  // params += `"APPLICATION_NAME": "${process.env.REACT_APP_BASENAME}"`;
  // params += `, "USER_NAME":"${getUserName()}"`;
  // params += `, "USER_GROUP_NAME":"${userGroup || getUserGroupName()}"`;
  // params = `{${params}}`;
  // return params;

  const dataItem = {
    APPLICATION_ID: process.env.REACT_APP_APPLICATION_ID,
    USER_NAME: getUserName(),
    // USER_GROUP_NAME: userGroup || getUserGroupName(),
  };

  return dataItem;
};

const VerticalLayout = (props) => {
  // console.log("verticalLayout");

  const [menuData, setMenuData] = useState([]);
  const [isFetchData, setIsFetchData] = useState(true);
  const [userGroup, setUserGroup] = useState(null);
  const [menuLayout, setMenuLayout] = useState([]);
  const [menuPermission, setMenuPermission] = useState([]);
  const [user, setUser] = useState({});

  const navigate = useNavigate();
  const location = useLocation();

  // useEffect(() => {
  //   if (!getUserName()) {
  //     // navigate("/login");
  //     ToastMessageWarning({ message: "You are not logging in. Please login" });
  //     navigate("/login");
  //     return;
  //   }
  // }, [location]);

  // console.log(location.pathname);
  // if (location.pathname == '/') {
  //   navigate('/leave-request');
  // }

  let menu = [];

  const onSuccessSearchData = (data) => {
    // console.log(data);
    // console.log("search menu");
    setIsFetchData(false);
    if (data.data.Status == true && data.data.ResultOnDb.length > 0) {
      setMenuLayout(navigation);
      setMenuPermission(data.data.ResultOnDb);
    } else if (data.data.Status == true && data.data.ResultOnDb.length === 0) {
      setMenuLayout(navigation);
      setMenuPermission(data.data.ResultOnDb);

      menu = navigation.filter((elem) => {
        return elem.meta?.publicGroup;
      });

      const message = {
        title: "Search GetMenu",
        message:
          "Your user group do not have permission to see the another menu.",
      };

      // ToastMessageWarning(message);

      // console.log("verticalLayout");

      // navigate("/auth/not-auth");
    }
  };

  const onErrorSearchData = (error) => {
    setIsFetchData(false);

    setMenuLayout(navigation);
    setMenuPermission([]);

    const message = {
      title: "Search GetMenu",
      message: error.message,
    };
    ToastMessageError(message);
  };

  // const { isLoading, error, data, isSuccess, isFetching } = useGetMenu(
  //   onSuccessSearchData,
  //   onErrorSearchData,
  //   getUrlParamSearch(userGroup),
  //   isFetchData
  // );

  const { isLoading, error, data, isSuccess, isFetching } = useGetMergeMenu(
    onSuccessSearchData,
    onErrorSearchData,
    getUrlParamSearch(userGroup),
    isFetchData
  );

  const onSuccessSearchDataEmployee = (data) => {
    // console.log(data);
    // console.log("search menu");
    setIsFetchData(false);
    if (data.data.Status == true && data.data.ResultOnDb.length > 0) {
      setUser(data.data.ResultOnDb);
    } else if (data.data.Status == true && data.data.ResultOnDb.length === 0) {
      setUser(data.data.ResultOnDb);
    }
  };

  const onErrorSearchDataEmployee = (error) => {
    setIsFetchData(false);
    setUser([]);

    const message = {
      title: "Search Employee",
      message: error.message,
    };
    ToastMessageError(message);
  };

  const {
    isLoading: isLoadingEmployee,
    error: errorEMployee,
    data: dataEmployee,
    isSuccess: isSuccessEmployee,
    isFetching: isFetchingEmployee,
  } = useSearchEmployeeByEmployeeCode(
    onSuccessSearchDataEmployee,
    onErrorSearchDataEmployee,
    {
      EMPLOYEE_CODE: getUserName(),
    },
    true
  );

  // useEffect(() => {
  //   const username = getUserName();

  //   if (!username) {
  //     navigate("/login");
  //   }
  // }, []);

  if (isLoading) {
    return (
      <Layout menuData={[]} {...props}>
        <p className="card-text placeholder-glow">
          <span className="placeholder col-7"></span>
        </p>
      </Layout>
    );
  }

  // Function Get Menu Intersection
  const getMenuIntersection = (Obj1, Obj2) => {
    return Obj1.filter((item1) =>
      Obj2.some((item2) => Number(item1.id) === Number(item2.id))
    );
  };

  const GetMenu = (element) => {
    let elements = [];
    let menuInside = [];

    if (element.header) {
      if (element.header !== "undefined") {
        menu.push(element);
      }
    }

    for (let idx = 0; idx < menuPermission.length; idx++) {
      const elementCompare = menuPermission[idx];

      if (elementCompare.id == element.id) {
        if (elementCompare.children && element.children) {
          elements = getMenuIntersection(
            element.children,
            elementCompare.children
          );
          element.children = elements;
          menuInside.push(element);
        } else {
          menu.push(element);
        }
      }
    }

    if (menuInside.length > 0) {
      menu.push(element);
    }
  };

  // Set default home menu
  for (let i = 0; i < 1; i++) {
    const element = menuLayout[i];
    menu.push(element);
  }

  // Menu application from Permission setting
  for (let i = 1; i < menuLayout.length; i++) {
    const element = menuLayout[i];

    if (element) {
      if (element.header === "undefined") {
        continue;
      }
      GetMenu(element);
    }
  }

  const removeMenu = (i) => {
    menu.splice(i, 1);
  };

  for (let i = 0; i < menu.length; i++) {
    let firstItemMenu = menu[i];
    let secondItemMenu = menu[i + 1] ? menu[i + 1] : menu[i];
    if (firstItemMenu?.header && secondItemMenu?.header) {
      removeMenu(i);
    }
  }

  if (menu[menu.length - 1]?.header !== undefined) menu.pop();

  // if (data?.data?.Status == true && data?.data?.ResultOnDb?.length === 0) {
  //   menu = navigation.filter((elem) => {
  //     return elem.meta?.publicGroup || elem.meta?.publicMenuGroup;
  //   });
  // }

  const positionCode =
    dataEmployee?.data?.ResultOnDb?.[0]?.EMPLOYEE_POSITION_CODE[0];

  if (!(positionCode === "S" || positionCode === "E" || positionCode === "M")) {
    // console.log(menu);
    menu = menu.filter((item) => {
      if (item?.header) {
        if (item.header.split(" ").some((e) => e === "Flex")) {
          // console.log(item);
          // poodit
          return false;
          // return true;
        }
      }
      if (item?.title) {
        if (item.title.split(" ").some((e) => e === "Flex")) {
          // console.log(item);
          // poodit
          return false;
          // return true;
        }
      }

      return true;
    });
  }

  localStorage.setItem(
    "menu",
    data?.data?.ResultOnDbRawData
      ? JSON.stringify(data.data.ResultOnDbRawData)
      : []
  );
  return (
    <>
      <Layout
        menuData={menu}
        {...props}
        userGroup={userGroup}
        setUserGroup={setUserGroup}
        isFetchData={isFetchData}
        setIsFetchData={setIsFetchData}
      >
        <Outlet />
      </Layout>
    </>
  );
};

export default VerticalLayout;
